import React from "react"
import { graphql } from "gatsby"
import ReactPlayer from "react-player"
import Layout from "../components/Layout"
import templateStyles from "../css/template.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SEO from "../components/SEO"

const ProjectTemplate = ({ data }) => {
  const { name, category, video, descriptions: {json} } = data.project

  return (
    <Layout>
      <SEO title={name} />
      <section className={templateStyles.template}>
        <ReactPlayer className={templateStyles.videoContainer}
                     url={video.file.url}
                     controls
                     config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                     onContextMenu={e => e.preventDefault()}
                     width="60%"
                     height="40%"/>
        <div className={templateStyles.center}>
          <h2>{name}</h2>
          <div className={templateStyles.info}>
            <p>{category}</p>
          </div>
          <article className={templateStyles.content}>
            {documentToReactComponents(json)}
          </article>
          <p className={templateStyles.desc}>
          </p>
          <AniLink fade to="/portfolio" className="btn-primary">Back to Portfolio</AniLink>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query($slug: String!) {
        project:contentfulProject(slug: {eq: $slug}) {
            name
            category
            descriptions {
                json
            }
            image {
                fluid {
                    ...GatsbyContentfulFluid
                }
            }
            video {
                file {
                    url
                }
            }
        }
    }
`

export default ProjectTemplate
